<template>
  <product :title="title"
    :swiper-data="swiperData"
    :slogan="slogan"
    :price="price"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://j.youzan.com/E921Kh"
    is-switch-title
    is-switch-slogan
    is-switch-price
    is-bus-city
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'stratos-3',
  components: {
    Product
  },
  data () {
    return {
      title: [
        'Amazfit 智能运动手表 3',
        'Amazfit 智能运动手表3  精英版'
      ],
      slogan: [
        '定制 1.34 英寸全圆反射屏 | 四星三模精准 GPS | 80 种运动模式<br/>FIRSTBEAT 专业运动算法 | Ultra / 智能双模式 | 最高 14 天长续航<br/>70 小时 GPS 续航 | 百城公交 | NFC 模拟门卡（仅支持中国大陆）'
      ],
      price: [
        1299
      ],
      colors: [
        '标准款'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/stratos-3/swiper/1.png',
          CDN_URL + '/images/product/stratos-3/swiper/2.png',
          CDN_URL + '/images/product/stratos-3/swiper/3.png',
          CDN_URL + '/images/product/stratos-3/swiper/4.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        CDN_URL + '/images/product/stratos-3/detail/01b.jpg',
        CDN_URL + '/images/product/stratos-3/detail/02.jpg',
        CDN_URL + '/images/product/stratos-3/detail/03.jpg',
        CDN_URL + '/images/product/stratos-3/detail/04.jpg',
        CDN_URL + '/images/product/stratos-3/detail/05.jpg',
        CDN_URL + '/images/product/stratos-3/detail/06.jpg',
        CDN_URL + '/images/product/stratos-3/detail/07b.jpg',
        CDN_URL + '/images/product/stratos-3/detail/08.jpg',
        CDN_URL + '/images/product/stratos-3/detail/09.jpg',
        CDN_URL + '/images/product/stratos-3/detail/10.jpg',
        CDN_URL + '/images/product/stratos-3/detail/11.jpg',
        CDN_URL + '/images/common/zepp-black.jpg',
        CDN_URL + '/images/product/stratos-3/detail/12.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/stratos-3/detail/13.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
